import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "rsuite/DatePicker/styles/index.css";
// styles

import "./assets/css/bootstrap.min.css";  
import "./assets/css/default.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/fp-nice-select.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/compiled-4.7.1.min.css";

import "./assets/css/main.css";
import "./assets/css/style.css";
// import ScrollProgressBar from "./components/ScrollProgressBar";

ReactDOM.render(
  <Router>
    <ToastContainer pauseOnFocusLoss={false} autoClose={3000} />
    <div style={{ overflow: "hidden" }}>
      <App />
    </div>
    {/* <ScrollProgressBar /> */}
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
