import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Blogs/BlogOne/Breadcrumb";
import Loader from "../Loader";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";


function MainSection({ data, isLoading, title }) {
  // const missions = [
  //   { id: 1, name: "Mission #1" },
  //   { id: 2, name: "Mission #2" },
  //   { id: 3, name: "Mission #3" },
  // ];
  // const [tab, setTab] = useState(missions[0].name);
  // const tabHandler = (e, value) => {
  //   e.preventDefault();
  //   setTab(value);
  // };
  const params = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [params]);

  return (
    <section
      className="binduz-er-about-us-area pb-60"
      style={{ paddingTop: "30px" }}
    >
      <div className="container">
        <div className="row">
          {/* <Breadcrumb title={title} /> */}
          <motion.div
            className="col-lg-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 4 }}
          >
            <div className="event_main pt-4">
              <div className="binduz-er-top-news-title">
                <h2>{title}</h2>
              </div>
              {!isLoading ? (
                <div dangerouslySetInnerHTML={{
                    __html: data,
                  }}
                />
              ) : (
                <div style={{
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </motion.div>
          {/* <div className=" col-lg-6">
            <div className="binduz-er-about-us-content">
              <h4 className="binduz-er-title">Mission & Vision</h4>
              <p>
                Are looking beyond ideation stage to, at the very least, beta,
                and for companies corporate-ready. Retailers want to see it all,
                so they usually have not precisely identified one tech they want
                to focus on. Even so, many believe there are less problematic
                and more effective ways to approach outdoors overcrowding than
                to avoid geotagging – it’s just not that simple. In her blog
                post, Williams recommends structural reforms for the USA,
              </p>
            </div>
          </div> */}
        </div>
        {/* <div className="row">
          <div className=" col-lg-3 col-md-6">
            <div className="binduz-er-about-us-box">
              <div className="binduz-er-icon">
                <img
                  src={require(`../../assets/images/icon/1.png`).default}
                  alt=""
                />
              </div>
              <h4 className="binduz-er-title">Project Progress</h4>
              <p>
                Are looking beyond ideation stage to, at the very least, beta,
                and for company
              </p>
            </div>
          </div>
          <div className=" col-lg-3 col-md-6">
            <div className="binduz-er-about-us-box">
              <div className="binduz-er-icon">
                <img
                  src={require(`../../assets/images/icon/2.png`).default}
                  alt=""
                />
              </div>
              <h4 className="binduz-er-title">Computer Science</h4>
              <p>
                Are looking beyond ideation stage to, at the very least, beta,
                and for company
              </p>
            </div>
          </div>
          <div className=" col-lg-3 col-md-6">
            <div className="binduz-er-about-us-box">
              <div className="binduz-er-icon">
                <img
                  src={require(`../../assets/images/icon/3.png`).default}
                  alt=""
                />
              </div>
              <h4 className="binduz-er-title">Management</h4>
              <p>
                Are looking beyond ideation stage to, at the very least, beta,
                and for company
              </p>
            </div>
          </div>
          <div className=" col-lg-3 col-md-6">
            <div className="binduz-er-about-us-box">
              <div className="binduz-er-icon">
                <img
                  src={require(`../../assets/images/icon/4.png`).default}
                  alt=""
                />
              </div>
              <h4 className="binduz-er-title">Social Sciences</h4>
              <p>
                Are looking beyond ideation stage to, at the very least, beta,
                and for company
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-60 align-items-center">
          <div className=" col-lg-6">
            <div className="binduz-er-about-play-thumb">
              <img
                src={
                  require(`../../assets/images/about-play-thumb.png`).default
                }
                alt=""
              />
              <div className="binduz-er-play">
                <a className="binduz-er-video-popup" href="#">
                  <i className="fas fa-play"></i>
                </a>
              </div>
            </div>
          </div>
          <div className=" col-lg-6">
            <div className="binduz-er-about-play-content">
              <h3 className="binduz-er-title">You can trust us very easily</h3>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {missions &&
                  missions.length > 0 &&
                  missions.map((missionItem) => (
                    <li
                      key={missionItem.id + Math.random()}
                      className="nav-item"
                      role="presentation"
                    >
                      <a
                        onClick={(e) => tabHandler(e, missionItem.name)}
                        className={`nav-link  ${
                          missionItem.name === tab ? "active" : ""
                        }`}
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        {missionItem.name}
                      </a>
                    </li>
                  ))}
              </ul>
              <div className="tab-content" id="pills-tabContent">
                {missions &&
                  missions.length > 0 &&
                  missions.map((missionItem) => (
                    <div
                      key={missionItem.id + Math.random()}
                      className={`tab-pane fade ${
                        missionItem.name === tab ? "active show" : ""
                      }`}
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="binduz-er-text">
                        <p>
                          Are looking beyond ideation stage to, at the very
                          least, beta, and for companies corporate-ready.
                          Retailers want to see it all, so they usually have not
                          precisely identified one tech they want to focus on.
                          Even so, many believe there are less problematic and
                          more effective ways to approach outdoors overcrowding
                          than to avoid geotagging – it’s just not that simple.
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default MainSection;
