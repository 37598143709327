/* eslint-disable no-unused-vars */
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import About from "./components/About/index";
import Archive from "./components/Archive/index";
import AuthorSection from "./components/AuthorSection/index";
import BlogOne from "./components/Blogs/BlogOne/index";
import BlogTwo from "./components/Blogs/BlogTwo/index";
import Contact from "./components/Contact/index";
import HomeOne from "./components/HomeOne/index";
import Login from "./components/Login";
import Register from "./components/Register";
import AccountDetail from "./components/AccountDetail";
import TicketDetails from "./components/TicketDetails";
import ClassiFields from "./components/ClassiFields";
import MessageCenter from "./components/MessageCenter";
import PostClassifield from "./components/PostClassifield";
import ClassiFieldDetails from "./components/ClassiFieldDetails";
import Calender from "./components/Calender";
import PostJobs from "./components/PostJobs";
import PostLocalBussiness from "./components/PostLocalBussiness";
import TopStories from "./components/TopStories";
import Articles from "./components/Articles";
import TopStoriesDetails from "./components/TopStoriesDetails";
import Megaphone from "./components/Megaphone";
import PostDoctor from "./components/PostDoctor";
import LocalBussinesses from "./components/LocalBussinesses";
import ThingsToDo from "./components/ThingsToDo";
import PlacesToVisit from "./components/PlacesToVisit";
import ReligiousPlaces from "./components/ReligiousPlaces";
import PostEvent from "./components/PostEvent";
import Doctor from "./components/Doctor";
import { PrivateRouterOutlet } from "./routes/private";
import { PublicRouterOutlet } from "./routes/public";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AdInformation from "./components/AdInformation";
import Services from "./components/Services";
import TimesDetailsPage from "./components/TimesDetailsPage";
import Jobs from "./components/Jobs";
import Organization from "./components/Organization";
import UpdateEvent from "./components/UpdateEvent";
import UpdateClassifield from "./components/UpdateClassifield";
import UpdateJob from "./components/UpdateJob";
import UpdateLocalBussiness from "./components/UpdateLocalBussiness";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import SuccessPage from "./components/SuccessPage";
import FailPage from "./components/FailPage";
import JobDetails from "./components/JobDetails";
import SubscriptionPage from "./components/SubscriptionPage";
import SearchModule from "./components/SearchModule";
import DoctorDetail from "./components/DoctorDetail";
import OTPscreen from "./components/OTPscreen";
import PostMegaphone from "./components/PostMegaphone";
import UpdateMegaphone from "./components/UpdateMegaphone";
import PodcastDetail from "./components/PodcastDetail";

export default function Routers() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/login" element={<PublicRouterOutlet />}>
          <Route path="" element={<Login />} />
        </Route>
        <Route exact path="/forgot-password" element={<PublicRouterOutlet />}>
          <Route path="" element={<ForgotPassword />} />
        </Route>
        <Route exact path="/register" element={<PublicRouterOutlet />}>
          <Route path="" element={<Register />} />
        </Route>
        <Route exact path="/otp-verification" element={<PublicRouterOutlet />}>
          <Route path="" element={<OTPscreen />} />
        </Route>

        <Route
          exact
          path="/ticket-details/:location_id/:id"
          element={<TicketDetails />}
        />
        <Route exact path="/ticket-details/:id" element={<TicketDetails />} />
        <Route exact path="/classifieds" element={<ClassiFields />} />
        <Route
          exact
          path="/classifield-detail/:id"
          element={<ClassiFieldDetails />}
        />

        <Route
          exact
          path="/reset-password/:token"
          element={<ResetPassword />}
        />
        <Route exact path="/classifield/:id" element={<ClassiFields />} />
        <Route exact path="/jobs-detail/:id" element={<JobDetails />} />
        <Route exact path="/local-bussiness" element={<LocalBussinesses />} />
        <Route exact path="/message-center" element={<MessageCenter />} />
        <Route exact path="/podcast" element={<PodcastDetail />} />

        <Route exact path="/archived" element={<Archive />} />
        <Route exact path="/author" element={<AuthorSection />} />
        <Route exact path="/organization" element={<Organization />} />
        <Route exact path="/calender" element={<Calender />} />
        <Route exact path="/search" element={<SearchModule />} />
        <Route exact path="/jobs" element={<Jobs />} />
        <Route exact path="/top-stories" element={<TopStories />} />
        <Route exact path="/megaphone" element={<Megaphone />} />
        <Route exact path="/details/:id" element={<TopStoriesDetails />} />
        <Route exact path="/doctor/:id" element={<DoctorDetail />} />
        <Route exact path="/doctor" element={<Doctor />} />
        <Route exact path="/articles" element={<Articles />} />
        <Route exact path="/things-to-do" element={<ThingsToDo />} />
        <Route exact path="/places-to-visit" element={<PlacesToVisit />} />
        <Route exact path="/religious-places" element={<ReligiousPlaces />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/ads-information" element={<AdInformation />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/times-detail/:id" element={<TimesDetailsPage />} />
        <Route exact path="/subscription" element={<SubscriptionPage />} />
        <Route exact path="/account-detail" element={<PrivateRouterOutlet />}>
          <Route path="" element={<AccountDetail />} />
        </Route>

        <Route
          exact
          path="/classifield/payment-success"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/classifield/payment-success/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/local-bussiness/payment-success"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/local-bussiness/payment-success/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/doctor/payment-success"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/event/payment-success"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/event/payment-success/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/megaphone/payment-success"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/megaphone/payment-success/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/job/payment-success"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        <Route
          exact
          path="/job/payment-success/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route>
        {/* <Route
          exact
          path="/payment-success"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<SuccessPage />} />
        </Route> */}
        <Route
          exact
          path="/classifield/payment-fail"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/classifield/payment-fail/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/event/payment-fail"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/event/payment-fail/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/megaphone/payment-fail"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/megaphone/payment-fail/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route exact path="/job/payment-fail" element={<PrivateRouterOutlet />}>
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/job/payment-fail/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/local-bussiness/payment-fail"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/local-bussiness/payment-fail/renew"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route
          exact
          path="/doctor/payment-fail"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<FailPage />} />
        </Route>
        <Route exact path="/classifield-post" element={<PrivateRouterOutlet />}>
          <Route path="" element={<PostClassifield />} />
        </Route>
        <Route exact path="/event-post" element={<PrivateRouterOutlet />}>
          <Route path="" element={<PostEvent />} />
        </Route>
        <Route exact path="/megaphone-post" element={<PrivateRouterOutlet />}>
          <Route path="" element={<PostMegaphone />} />
        </Route>
        <Route exact path="/event-update/:id" element={<PrivateRouterOutlet />}>
          <Route path="" element={<UpdateEvent />} />
        </Route>
        <Route
          exact
          path="/classifield-update/:id"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<UpdateClassifield />} />
        </Route>
        <Route exact path="/job-update/:id" element={<PrivateRouterOutlet />}>
          <Route path="" element={<UpdateJob />} />
        </Route>
        <Route
          exact
          path="/megaphone-update/:id"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<UpdateMegaphone />} />
        </Route>
        <Route
          exact
          path="/local-bussiness-update/:id"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<UpdateLocalBussiness />} />
        </Route>
        <Route exact path="/jobs-post" element={<PrivateRouterOutlet />}>
          <Route path="" element={<PostJobs />} />
        </Route>
        <Route exact path="/doctor-post" element={<PrivateRouterOutlet />}>
          <Route path="" element={<PostDoctor />} />
        </Route>
        <Route
          exact
          path="/local-bussiness-post"
          element={<PrivateRouterOutlet />}
        >
          <Route path="" element={<PostLocalBussiness />} />
        </Route>
        <Route exact path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}
