import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getClassifieldCatData,
  getLatestClassifieldData,
} from "../../services/HomeServices";
import { toast } from "react-toastify";
import { formatDate, minimizeName } from "../../lib/constant";

import classified_icon_01 from "../../assets/images/classified_icon_01.png";
import classified_icon_02 from "../../assets/images/classified_icon_02.png";
import classified_icon_03 from "../../assets/images/classified_icon_03.png";
import classified_icon_04 from "../../assets/images/classified_icon_04.png";
import classified_icon_05 from "../../assets/images/classified_icon_05.png";
import classified_icon_06 from "../../assets/images/classified_icon_06.png";
import classified_icon_07 from "../../assets/images/classified_icon_07.png";
import classified_icon_08 from "../../assets/images/classified_icon_08.png";
import classified_icon_09 from "../../assets/images/classified_icon_09.png";
import classified_icon_10 from "../../assets/images/classified_icon_10.png";
import classified_icon_11 from "../../assets/images/classified_icon_11.png";
import classified_icon_12 from "../../assets/images/classified_icon_12.png";

const iconArray = [
  classified_icon_01,
  classified_icon_02,
  classified_icon_03,
  classified_icon_04,
  classified_icon_05,
  classified_icon_06,
  classified_icon_07,
  classified_icon_10,
  classified_icon_08,
  classified_icon_09,
  classified_icon_10,
  classified_icon_11,
];



const LatestClassifiedModule = ({ isOnlyClassified }) => {
  const navigate = useNavigate();
  const [latestClassiLoading, setlatestClassiLoading] = useState(false);
  const [latestClassiData, setlatestClassiData] = useState([]);
  const [categories, setcategories] = useState([]);
let indexOfColor = 0;

  useEffect(() => {
    latestClassifeildData();
    getCategories();
  }, []);

  const getCategories = async () => {
    await getClassifieldCatData()
      .then((response) => {
        const array = response?.data?.data?.filter(
          (a) => a?.name !== "Pin Hopes"
        );
        setcategories(array);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const latestClassifeildData = async () => {
    setlatestClassiLoading(true);
    await getLatestClassifieldData()
      .then((response) => {
        setlatestClassiLoading(false);
        setlatestClassiData(response?.data?.data);
      })
      .catch((error) => {
        setlatestClassiLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div className="container-fluid bg_gray">
      <div className="container">
        <div className="row prime_listings">
          <div className="col-sm-6">
            <h2>Latest Classifieds</h2>
          </div>
        </div>
        <div className="row pt-4">
          {latestClassiData?.slice(0, 12)?.map((x) => (
            <div className="col-sm-3 ">
              <div className="lc_card">
                <p>
                  <div
                    style={{ minHeight: "123px" }}
                    className="line-clump-2"
                    dangerouslySetInnerHTML={{
                      __html: x?.description,
                    }}
                  />
                </p>
                <div className="row">
                  <div className="col-6">
                    <label>
                      <i className="far fa-calendar" />{" "}
                      {formatDate(new Date(x?.start_date))}
                    </label>
                  </div>
                  <div className="col-6">
                    <Link to={`/classifield-detail/${x?.id}`}>More</Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {!isOnlyClassified && (
          <>
            <div className="row prime_listings pt-3">
              <div className="col-sm-6">
                <h2>Other Categories</h2>
              </div>
            </div>
            <div className="row pt-4">
              {categories?.map((x, i) => {
                // let indexOfColor = Math.floor(Math.random() * 11) + 1;
 if (i > 10 && indexOfColor > 10) {
   indexOfColor = 1;
 } else {
   indexOfColor++;
 }
                return (
                  <div className="col-sm-2 col-6">
                    <Link
                      className={`classfi_icon cf_icon${indexOfColor}`}
                      to={`/classifield/${x?.id}`}
                    >
                      <img
                        src={iconArray[i] || classified_icon_07}
                        alt="Art/Dance/Music"
                      />{" "}
                      <span>{minimizeName(x?.name)}</span>
                    </Link>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LatestClassifiedModule;
